import React from "react";
import {graphql, Link} from "gatsby";
import {InformativePostTemplateQuery} from "../../graphql-types";
import Layout from "components/Layout";
import {contentfulRenderer} from "../utils/contentfulRenderer";
import {PropsWithTranslationSheet} from "../i18n/TranslationSheet";
import AmazonBanner from "components/AmazonBanner";
import {
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    FacebookShareButton
} from "react-share";

export const informativePostTemplateQuery = graphql`
    query InformativePostTemplate($id: String!) {
        contentfulEntry(id: {eq: $id}) {
            ... on ContentfulInformativePost {
                title
                subtitle
                link
                referredPage
                createdAt
                updatedAt
                image {
                    file {
                        url
                    }
                    title
                }
                content {
                    raw
                    references {
                        ... on ContentfulAsset {
                            __typename
                            title
                            file {
                                url
                            }
                            contentful_id
                        }
                    }
                }
            }
        }
    }
`;

interface RelatedPost {
    slug: string;
    title: string;
    image: string;
}

const InformativePostTemplate: React.FC<
    PropsWithTranslationSheet & {
        data: InformativePostTemplateQuery;
        pageContext: {slug: string; previousPost: RelatedPost | null; nextPost: RelatedPost | null};
    }
> = ({data, pageContext: {slug, t, previousPost, nextPost}}) => {
    const {
        title,
        subtitle,
        // link,
        // referredPage,
        createdAt,
        updatedAt,
        content,
        image: {
            title: imageTitle,
            file: {url}
        }
    } = data.contentfulEntry;

    return (
        <Layout
            t={t}
            isPost
            slug={slug}
            title={title}
            metaDescription={subtitle}
            cardImagePath={`https:${url}`} //url has //prefix
            datePublished={new Date(createdAt)}
            dateUpdated={new Date(updatedAt)}
        >
            <div className="relative">
                <div className="absolute top-3 flex justify-end gap-3 w-full">
                    <FacebookShareButton
                        url={t.ROOT_URL + "/" + slug}
                        title={title}
                        quote={subtitle}
                    >
                        <FacebookIcon size={30} className={"rounded"} />
                    </FacebookShareButton>

                    <FacebookMessengerShareButton
                        url={t.ROOT_URL + "/" + slug}
                        appId={"142691081113133"}
                    >
                        <FacebookMessengerIcon size={30} className={"rounded"} />
                    </FacebookMessengerShareButton>
                </div>
            </div>

            <main className="lg:mt-10">
                <div className="mb-4 md:mb-0 w-full mx-auto relative">
                    <div className="mb-4 px-4 lg:px-0">
                        <h1 className="text-4xl font-semibold text-gray-800 leading-tight">
                            {title}
                        </h1>
                        <h2>{subtitle}</h2>
                    </div>

                    <img
                        loading="eager"
                        src={`https:${url}`}
                        className="w-full object-contain lg:rounded h-60"
                        alt={imageTitle}
                    />
                </div>

                <div className="mt-8">
                    <hr />
                </div>

                <div className="mt-8">
                    <AmazonBanner t={t} />
                </div>

                <div className="flex flex-col lg:flex-row lg:space-x-12">
                    <div className="prose post-container mt-8 text-gray-700 text-lg leading-relaxed w-full">
                        {contentfulRenderer(content)}
                    </div>
                </div>
            </main>

            {(previousPost || nextPost) && (
                <div className="mt-8 py-4">
                    <span className="font-bold text-xl ">{t.READ_FURTHER}</span>

                    <div className="flex justify-between mt-4 gap-4">
                        {previousPost && (
                            <Link
                                to={"/" + previousPost.slug + "/"}
                                className="group flex flex-1 flex-col overflow-hidden rounded-lg shadow-lg"
                            >
                                <div className="flex-shrink-0">
                                    <img
                                        loading="lazy"
                                        className="h-20 w-full object-cover group-hover:brightness-125"
                                        src={previousPost.image}
                                        alt={previousPost.title}
                                    />
                                </div>
                                <div className="flex flex-1 flex-col justify-between bg-white p-2">
                                    <p className="text-md font-semibold text-gray-900">
                                        {previousPost.title}
                                    </p>
                                </div>
                            </Link>
                        )}

                        {nextPost && (
                            <Link
                                to={"/" + nextPost.slug + "/"}
                                className="group flex flex-1 flex-col overflow-hidden rounded-lg shadow-lg"
                            >
                                <div className="flex-shrink-0">
                                    <img
                                        loading="lazy"
                                        className="h-20 w-full object-cover group-hover:brightness-125"
                                        src={nextPost.image}
                                        alt={nextPost.title}
                                    />
                                </div>
                                <div className="flex flex-1 flex-col justify-between bg-white p-2">
                                    <p className="text-md font-semibold text-gray-900">
                                        {nextPost.title}
                                    </p>
                                </div>
                            </Link>
                        )}
                    </div>
                </div>
            )}
        </Layout>
    );
};

export default InformativePostTemplate;
